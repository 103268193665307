










































import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';
import {SelectOption} from "@/types/ExtraTypes";
import SearchAutoComplete from "@shared_vue/components/searchautocomplete/SearchAutoComplete.vue";
import SearchProfileCard from "../../../../../shared_vue/components/viewcomponents/ProfileCard.vue";
import {CaseApiFactory, CaseDTO, CaseStatus, Configuration} from "../../../../../shared_vue/openapi/caseapi";
import {SuggestionDTO} from "@shared_vue/openapi/caseapi/api";

@Component({
  components: { SearchAutoComplete, SearchProfileCard },
})
export default class MainSearchModal extends Vue {
  @Prop(String) readonly title: string | undefined;
  @Prop(String) readonly message: string | undefined;
  @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
  @PropSync('dismissed', { type: Boolean }) syncedDismiss!: boolean;
  private ui = vxm.ui;
  private auth = vxm.auth;
  private waitForMe = false;
  private showWait=false;
  private timer: ReturnType<typeof setTimeout> | undefined;
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
  //@ts-ignore
  private selected: SuggestionDTO={};
  private clearMe(){
    this.waitForMe = false;
  }

  private waitABit(){
    let outerThis = this;
    this.waitForMe = true;
    this.timer = setTimeout(()=>{
      outerThis.clearMe();
    }, 200)
  }
  
  private isSelected(cell: string){
    if (!this.selected)
      return false;
    return (this.selected?.cell === cell)
  }
  
  private setUser(data: SuggestionDTO){
    console.log(data);
    this.selected=data;
  }
  private searchList : Array<SuggestionDTO> = [];
  private async createCase(){
    this.$emit('createcase',this.selected.uid)
    this.syncedShow=false;
    this.syncedDismiss=true;
    
  }

  private openProfile(){
    this.syncedShow=false;
    this.syncedDismiss=true;
    this.$router.push(`/${this.selected.url}`);
  }
  
  get suggestions(){
    return this.searchList;
  }

  private async search(what: string){
    console.log('search ' + what)
    if (what.length<3){
      console.log('too short')
      return;
    }
    if (this.waitForMe){
      console.log('waiting')
      return;
    }
    this.waitABit();
    let searchRes = await this.caseApi.caseSearch(what);
    if (searchRes.status==200){
      console.log(searchRes.data)
      this.searchList = searchRes.data?.slice(0,6);
    }
  }

}
