






































import AgoraRTC from "agora-rtc-sdk-ng"
import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import SearchAutoComplete from "@shared_vue/components/searchautocomplete/SearchAutoComplete.vue";
import {CallConnectDTO, CaseApiFactory, CaseStatus, StartRecordResponse} from "@shared_vue/openapi/caseapi/api";
import {Configuration} from "@shared_vue/openapi/caseapi/configuration";
import {SelectOption} from "@/types/ExtraTypes";
import axios from "axios";
import {FileApiFactory} from "../../../shared_vue/openapi/myvaultapi";


@Component({
        components: {
            SearchAutoComplete
        },
    })

    export default class DashAsideRight extends Vue {
      private timer: ReturnType<typeof setTimeout> | undefined;
      private ui = vxm.ui;
      private searchText = ''
      private connecting: boolean = false;
      private waitForMe = false;
      private agoraEngine: any = null;
      private searchList : Array<SelectOption> = [];
      private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
      private fileApi = FileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL)
      private callMessage: string = "";
      private auth = vxm.auth;
      private caseStore = vxm.case;
      private buttonText: string = "Activate Call";
      private startRecordResponse: StartRecordResponse|undefined;
      private isRecording: boolean = false;
      private options: any=
          {
            // Pass your App ID here.
            appId: '962b6a173ffd47f9a6cadd2ce2b08112',
            // Set the channel name.
            channel: 'AAAConnect',
            // Pass your temp token here.
            token: '',
            // Set the user ID.
            uid: 100,
            // Set token expire time.
            ExpireTime: 60,
            // The base URL to your token server. For example, https://agora-token-service-production-92ff.up.railway.app".
            serverUrl: 'https://agoratoken.gen11project.com' // 'https://xytq2ftmhznirrbu5zfnh3ygkq0jemnb.lambda-url.eu-west-1.on.aws'
          };
      private channelParameters: any =
          {
            // A variable to hold a local audio track.
            localAudioTrack: null,
            // A variable to hold a remote audio track.
            remoteAudioTrack: null,
            // A variable to hold the remote user id.
            remoteUid: null,
          };
      
      get canCall(){
        return this.caseStore.okToCall;
      }

      @Watch('caseStore.okToCall')
      onCanCall(val: boolean, oldval: boolean) {
        if (!oldval && val){ //switched off to on
          if (this.caseStore.openCase.status==CaseStatus.New) {
            this.begin();
          }
        }
      }
      
      
      private navigateUser(whom: SelectOption){
        console.log('navigating to: ' + whom.value)
        this.$router.push(`/${whom.value}`)
      }

      private async begin(){ //<-- entry
        if (this.buttonText==="Disconnect"){ //I know, sorry
          await this.leave();
          this.callMessage="";
          this.buttonText="Activate Call";
        } else {
          this.startBasicCall();
        }
      }

      private async disconnectMe(){
        await this.leave();
      }

      private async join() {
        this.callMessage="Connecting";
        try {
          this.connectCall(); //no await
        } catch (e){
          console.log(e);
        }
        this.options.token=await this.FetchToken(); 
        const promise1 = this.agoraEngine.join(this.options.appId, this.options.channel, this.options.token, this.options.uid);
        const promise2 = this.caseApi.caseStartRecording(this.options.channel, "101", this.caseStore.openCase!.id!);
        const [result1, result2] = await Promise.all([promise1, promise2]);
        // this.callMessage="Joined channel: " + this.options.channel;        
        this.buttonText = "Disconnect";
        // Create a local audio track from the microphone audio.
        this.channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Publish the local audio track in the channel.
        await this.agoraEngine.publish(this.channelParameters.localAudioTrack);
        console.log("Publish success!");
        this.connecting=false;
        //connect recorder
        console.log('start recording');
        
        console.log(result1);
        console.log(result2);
        this.startRecordResponse = result2.data;
        this.isRecording = true;
        
      }

      private async leave() {
        // Destroy the local audio track.
        this.channelParameters.localAudioTrack.close();
        // Leave the channel
        await this.agoraEngine.leave();
        console.log("You left the channel");
        console.log('stopping record')
        let result = await this.caseApi.caseStopRecording(this.options.channel, "101",this.startRecordResponse!.sid!, this.startRecordResponse!.resourceId!, this.caseStore.openCase!.id!)
        console.log(result)
        this.isRecording = false;
      }

      private startBasicCall() {
        // Create an instance of the Agora Engine
        this.options.channel = 'AAAConnect_' + this.caseStore.openCase.caseNumber!.replace(/-/g, "");
        console.log(`channel is: ${this.options.channel}`);
        this.agoraEngine = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

        // Listen for the "user-published" event to retrieve an AgoraRTCRemoteUser object.
        //@ts-ignore
        this.agoraEngine.on("user-published", async (user, mediaType) => {
          // Subscribe to the remote user when the SDK triggers the "user-published" event.
          await this.agoraEngine.subscribe(user, mediaType);
          console.log("subscribe success");

          // Subscribe and play the remote audio track.
          if (mediaType === "audio") {
            this.channelParameters.remoteUid = user.uid;
            // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
            this.channelParameters.remoteAudioTrack = user.audioTrack;
            // Play the remote audio track. 
            this.channelParameters.remoteAudioTrack.play();
            this.connecting = false;
            this.callMessage = "Connected";
          }

          // Listen for the "user-unpublished" event.
          const outerthis2 = this;
          //@ts-ignore
          this.agoraEngine.on("user-unpublished", user => {
            console.log(user.uid + "has left the channel");
            this.callMessage="User Disconnected";
            this.buttonText = "Activate Call";
            if (outerthis2.isRecording){
              let result = outerthis2.caseApi.caseStopRecording(this.options.channel, "101",this.startRecordResponse!.sid!, this.startRecordResponse!.resourceId!, this.caseStore.openCase!.id!).then((res)=>{
                console.log(result)
                outerthis2.isRecording = false;
              })
              
            }
          });
          const outerthis = this;
          this.agoraEngine.on("token-privilege-will-expire", async function ()
          {
            outerthis.options.token = await outerthis.FetchToken();
            await outerthis.agoraEngine.renewToken(outerthis.options.token);
          });
        });
        this.join();
      }

      private async connectCall(){
        
        this.connecting = true;

        // let selfieUrl = await this.getSelfieUrl();
        //also make a call out to caseApi for push message
        let callConnect: CallConnectDTO = {
          operator: this.auth.DisplayName!,
          channel: 'channelValue',
          caseId: this.caseStore.openCase!.id!,
          caseNumber: this.caseStore.openCase!.caseNumber!,
          //@ts-ignore
          appId: null,
          //@ts-ignore
          deviceId: null,
          //@ts-ignore
          imageUrl: null
        };
        console.log('going to connect')
        this.caseApi.caseCallConnect(callConnect)
      }
      
      private async getSelfieUrl(){
        //need the signed URL unless we make all selfies public but that seems like a POPI thing
        try {
          let link = await this.fileApi.fileGetFileLinkByKey("selfie.png");
          return link.data;
        } catch (e){
          return null;
        }
      }

      private async FetchToken()
      {
        let idToken = this.auth.idToken;
        const outerthis = this;
        return new Promise(function (resolve) {
          axios.defaults.withCredentials = false
          const finalUrl = outerthis.options.serverUrl+'/rtc/'+outerthis.options.channel+'/publisher/uid/'+outerthis.options.uid+'/?expiry='+ outerthis.options.ExpireTime;
          console.log('final url is: ' + finalUrl)
          axios.get(finalUrl, {
            headers: {
              Authorization: idToken
            }
          })
              .then(response => {
                console.log(response.data.rtcToken);
                resolve(response.data.rtcToken);
              })
              .catch(error => {
                console.log(error);
              }).finally(()=>{
              axios.defaults.withCredentials = true;
          });
        });
      }
      
      private clearMe(){
        this.waitForMe = false;
      }
      
      private waitABit(){
        let outerThis = this;
        this.waitForMe = true;
        this.timer = setTimeout(()=>{
          outerThis.clearMe();
        }, 200)
      }
      
      private async search(what: string){
        console.log('search ' + what)
        if (what.length<3){
          console.log('too short')
          return;
        }
        if (this.waitForMe){
          console.log('waiting')
          return;
        }
        this.waitABit();
        let searchRes = await this.caseApi.caseSearch(what);
        if (searchRes.status==200){
          console.log(searchRes.data)
          this.searchList = searchRes.data.map((el)=>new SelectOption(el.suggestion, el.url));
        }
      }
    }

