































import TheFooter from '@/containers/TheFooter.vue'
import TbButton from "@/views/TbButton.vue";

import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import Casebook from "@/views/widgets/Casebook.vue";
import {CaseApiFactory, CaseDTO, CaseStatus, Configuration, PanicDTO} from "@shared_vue/openapi/caseapi";
import MainSearchModal from "@/views/widgets/modals/MainSearchModal.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";

@Component({
    components: {
      WaitModal,
      Casebook,
      TbButton,
      MainSearchModal
    },
})

export default class DashAsideLeft extends Vue {
    private ui = vxm.ui;
    private showWait = false;
    private showSearch = false;
    private isOnMobile () {
        return Boolean(getComputedStyle(this.$el).getPropertyValue('--is-mobile'))
    }
    private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL}, process.env.VUE_APP_CASE_API_URL)
    private auth = vxm.auth;
    private searchMe(){
      this.showSearch = true;
    }
    async createCase(ownerid: string | undefined) {
      this.showWait=true;
    //mock actual login
    try {
      let caseInstance: CaseDTO = {
        status: CaseStatus.New,
        officerId: this.auth.usr!.uid,
        ownerId: ownerid??undefined,
      };
      
      let createdCase = await this.caseApi.caseCreateCase(caseInstance);
      this.$router.push(`/dashboard/livecase/${createdCase!.data!.id}`)
    } catch (err) {
      console.log("Failed with " + err);
      alert('ERROR')
    } finally {
      this.showWait=false;
    }
  }

}

