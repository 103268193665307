



















































































































































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import firebase from "firebase";
import Cookies from "js-cookie";
@Component({
  components: {
  },
})

export default class DashHeader extends Vue {
  private ui = vxm.ui;
  private case = vxm.case;
  private auth = vxm.auth;
  private darkMode: boolean = false;

  private myVaultPath = process.env.VUE_APP_MYVAULT_NAV;   

  private toggleDarkMode() {
    this.ui.toggleDarkMode();
    document.body.classList.toggle('c-dark-theme')
  }

  get name(){
    return this.auth.usr?.displayName;
  }
  private logmeout() {
    console.log('goodbye')
    // let res = Cookies.remove('__session', { path: '' })
    // debugger
    firebase
        .auth()
        .signOut()
        .then(() => {
          this.case.setOp('');
          // vxm.ui.RegisterState = 1;
          // this.$router.push("/");
        })
        .catch((error) => {
          // An error happened.
        });
  }
}

